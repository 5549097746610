import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Box, Container, Flex, Grid, Heading, Text } from "@theme-ui/components"
import Layout from "../components/layout"
import { getVideoPath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import Breadcrumbs from "../components/breadcrumbs"
import BannerThumb from "../components/bannerThumb"
import VideoThumb from "../components/videoThumb"
import VideoEmbed from "../components/blocks/embed"
import { i18nContext } from "../context/i18nContext"
import * as Duration from "tinyduration"
import BannerListVertical from "../components/blocks/BannerListVertical"

// const LocationsMap = loadable(
//   () => import("../components/blocks/locationMap"),
//   { ssr: false }
// )

const Page = ({ data: { page, site, videos }, location }) => {
  // console.log(page)
  const favicon = useFavicon().site.faviconMetaTags
  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })
  const isBrowser = typeof window !== "undefined"

  let tmpi18nPaths = []

  pageAllSlugLocales.map(locale => {
    if (page.channel.pageLink.slug !== "cogeneration-channel") {
      tmpi18nPaths.push({
        locale: locale.locale,
        value: getVideoPath(page, locale.locale),
      })
    } else if (
      page.channel.pageLink.slug === "cogeneration-channel" &&
      locale.locale !== "de" &&
      locale.locale !== "fr"
    ) {
      tmpi18nPaths.push({
        locale: locale.locale,
        value: getVideoPath(page, locale.locale),
      })
    }
  })

  useEffect(() => {
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer.push({
        event: "video_view",
        video_title: page.title,
        video_link: page.videoLink,
        video_category: page.category.slug,
        video_channel: page.channel.pageLink.slug,
        pageURL: isBrowser && window.location.href,
      })
    }
  }, [])

  const i18nPaths = tmpi18nPaths

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "VideoObject",
    name: page.title,
    description: page.description,
    thumbnailUrl: page.thumbnail ? page.thumbnail.url : null,
    uploadDate: page.meta.firstPublishedAt,
    contentUrl: page.videoLink,
    duration: Duration.serialize({ seconds: page.duration }),
  }

  return (
    <Layout
      pageSlug={page.channel.pageLink.slug}
      locale={page.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms favicon={favicon}>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <html lang={page.locale} />
        <title>{page.title} | NetZeroTube</title>
        <meta name="description" content={page.description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={page.description} />
        {page.thumbnail && (
          <meta property="og:image" content={page.thumbnail.url} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
      </HelmetDatoCms>
      <Container>
        <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
          <Breadcrumbs page={page} videoCategory={page.category} />
        </Box>
        <Grid
          columns={["1fr", "1fr", ".62fr .38fr "]}
          gap={[4, 4, 6, 8]}
          sx={{
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ pt: [0], pb: [6], textAlign: "left" }}>
            <Box>
              <VideoEmbed video={page} />
            </Box>
            <Flex
              sx={{
                mt: [5],
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                sx={{
                  fontSize: [0],
                  letterSpacing: "0.09",
                  lineHeight: "1",
                }}
              >
                {new Date(page.meta.firstPublishedAt)
                  .toLocaleDateString()
                  .replaceAll("/", ".")}
              </Text>
            </Flex>
            <Heading
              as="h2"
              variant="h2"
              color="dark"
              sx={{
                my: [4],
              }}
            >
              {page.title}
            </Heading>
            <Box variant="links.badge.smallCategory">{page.category.title}</Box>
            <Box
              sx={{
                fontFamily: "alternative",
                py: [3],
                fontSize: [3, 4],
                lineHeight: "1.3",
                p: {
                  color: "dark",
                  my: [3],
                  fontSize: [3, 4],
                  fontFamily: "alternative",
                  lineHeight: "1.3",
                  fontWeight: "400",
                },
              }}
              dangerouslySetInnerHTML={{ __html: page.description }}
            />
            {page.language && (
              <Box>
                <Box as="span" sx={{ color: "lightGrey" }}>
                  <i18nContext.Consumer>
                    {t => t.originLanguage}
                  </i18nContext.Consumer>
                  :
                </Box>{" "}
                {page.language.name}
              </Box>
            )}
            {page.country && (
              <Box>
                <Box as="span" sx={{ color: "lightGrey" }}>
                  <i18nContext.Consumer>{t => t.origin}</i18nContext.Consumer>:
                </Box>{" "}
                {page.country.name}
              </Box>
            )}
            <Box
              sx={{
                borderBottom: "1px solid",
                pt: [6],
                mb: [6],
                borderColor: "#DADADA",
              }}
            ></Box>
            <Box>
              <Heading as="p" variant="h6" sx={{ textTransform: "uppercase" }}>
                <i18nContext.Consumer>{t => t.speaker}</i18nContext.Consumer>
                {page.speaker.length > 1 && "s"}
              </Heading>
              {page.speaker.map(speaker => (
                <Box sx={{ py: [1] }}>
                  <Box sx={{ fontFamily: "alternative" }}>
                    <Text color="primary" sx={{ fontWeight: "600" }}>
                      {speaker.name}
                    </Text>
                  </Box>
                  <Box sx={{ fontFamily: "alternative" }}>
                    <Text>{speaker.jobTitle && speaker.jobTitle.trim()}</Text>
                    {speaker.jobTitle && speaker.company && ", "}
                    {speaker.company && <Text>{speaker.company}</Text>}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                mb:
                  page.banner || page.category.bannersCategory.length > 0
                    ? [5]
                    : [0],
              }}
            >
              {page.banner && (
                <Flex sx={{ mb: [4], img: { width: "100%" } }}>
                  <BannerThumb banner={page.banner} small />
                </Flex>
              )}
              <Box>
                {page.category.bannersCategory.length > 0 && (
                  <BannerListVertical
                    banners={page.category.bannersCategory}
                    channel={page.channel.pageLink.slug}
                  />
                )}
              </Box>
            </Box>

            <Box sx={{ mb: [6, 8], mt: [5, 0] }}>
              <Heading
                as="h3"
                variant="h2"
                sx={{
                  borderTop: "1px solid",
                  borderColor: "dark",
                  pb: [4],
                  pt: [2],
                  mt: [0],
                }}
              >
                <i18nContext.Consumer>
                  {t => t.relatedVideos}
                </i18nContext.Consumer>
              </Heading>
              <Grid gap={[3, 4, 5]}>
                {videos &&
                  videos.nodes.length > 0 &&
                  videos.nodes
                    .filter(video => video.id !== page.id)
                    .slice(0, 4)
                    .map(video => (
                      <VideoThumb
                        side={true}
                        video={video}
                        category={page.title}
                      />
                    ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query VideoQuery($categoryId: String!, $id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsVideo(id: { eq: $id }) {
      id
      ...VideoPageDetails
      ...AllVideoSlugLocales
      model {
        apiKey
      }
    }
    videos: allDatoCmsVideo(
      limit: 5
      filter: {
        category: { id: { eq: $categoryId } }
        locale: { eq: $locale }
        slug: { ne: null }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        description
        locale
        duration
        meta {
          firstPublishedAt
        }
        videoId
        videoLink
        thumbnail {
          desktop: gatsbyImageData(placeholder: BLURRED, width: 600)
          mobile: gatsbyImageData(
            placeholder: BLURRED

            imgixParams: { fit: "crop", ar: "16:10", h: "800" }
          )
        }
        channel {
          id
          pageLink {
            id
            slug
          }
        }
        speaker {
          id
          name
        }
        category {
          id
          title
          locale
          model {
            apiKey
          }
          channel {
            pageLink {
              id
              slug
            }
          }
        }
        ...AllVideoSlugLocales
      }
    }
  }

  fragment VideoPageDetails on DatoCmsVideo {
    id
    title
    slug
    description
    locale
    duration
    meta {
      firstPublishedAt
    }
    banner {
      id
      title
      image {
        gatsbyImageData(width: 500, placeholder: BLURRED)
        alt
        title
      }
      url
    }
    channel {
      id
      pageLink {
        id
        slug
      }
    }
    videoId
    videoLink
    thumbnail {
      desktop: gatsbyImageData(placeholder: BLURRED, width: 1480)
      mobile: gatsbyImageData(
        placeholder: BLURRED

        imgixParams: { fit: "crop", ar: "16:10", h: "800" }
      )
      url
    }
    language {
      id
      name
    }
    country {
      id
      name
    }
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    speaker {
      id
      name
      code
      jobTitle
      company
    }
    category {
      id
      title
      slug
      locale
      model {
        apiKey
      }
      channel {
        pageLink {
          id
          slug
        }
      }
      bannersCategory {
        url
        title
        image {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          url
          alt
          title
        }
      }
    }
  }

  fragment AllVideoSlugLocales on DatoCmsVideo {
    _allSlugLocales {
      value
      locale
    }
  }
`
